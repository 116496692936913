"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const auth0_react_1 = require("@auth0/auth0-react");
const useSQLMutation2_1 = require("./useSQLMutation2");
const useSQLQuery2_1 = require("./useSQLQuery2");
const log_1 = require("../utils/log");
function useEvercent() {
    const { user } = (0, auth0_react_1.useAuth0)();
    const email = {
        userEmail: user?.email,
    };
    const updateCachedUserData = (oldData, newData) => {
        if (oldData?.data && newData) {
            return {
                ...oldData,
                data: {
                    ...oldData.data,
                    userData: {
                        ...oldData.data.userData,
                        ...newData,
                    },
                },
            };
        }
    };
    const updateCachedCategories = (oldData, newData) => {
        if (oldData?.data && newData) {
            return {
                ...oldData,
                data: {
                    ...oldData.data,
                    categoryGroups: [...newData.newCategories],
                    excludedCategories: [...newData.excludedCategories],
                    autoRuns: [...newData.newAutoRuns],
                },
            };
        }
    };
    const updateCachedAutoRuns = (old, newData) => {
        (0, log_1.log)("Checking...........");
        (0, log_1.log)(old);
        (0, log_1.log)(newData);
        if (old?.data && newData) {
            if (newData?.autoRuns?.length == 0) {
                return {
                    ...old,
                    data: {
                        ...old.data,
                        autoRuns: [],
                    },
                };
            }
            return {
                ...old,
                data: {
                    ...old.data,
                    autoRuns: [...newData?.autoRuns],
                },
            };
        }
    };
    const saveAutoRuns = () => {
        return (0, useSQLMutation2_1.useSQLMutation2)("autoRun", "saveAutoRunDetails", "user", "getAllUserData", false, updateCachedAutoRuns);
    };
    const cancelAutoRuns = () => {
        return (0, useSQLMutation2_1.useSQLMutation2)("autoRun", "cancelAutoRuns", "user", "getAllUserData", false, updateCachedAutoRuns);
    };
    const updateUserDetails = () => {
        return (0, useSQLMutation2_1.useSQLMutation2)("user", "updateUserDetails", "user", "getAllUserData", false, updateCachedUserData);
    };
    const updateMonthsAhead = () => {
        return (0, useSQLMutation2_1.useSQLMutation2)("user", "updateMonthsAheadTarget", "user", "getAllUserData", false, updateCachedUserData);
    };
    const updateCategories = () => {
        return (0, useSQLMutation2_1.useSQLMutation2)("user", "updateCategoryDetails", "user", "getAllUserData", false, updateCachedCategories);
    };
    const changeBudget = () => {
        return (0, useSQLMutation2_1.useSQLMutation2)("budget", "switchBudget");
    };
    const connectToYNAB = () => {
        return (0, useSQLMutation2_1.useSQLMutation2)("budget", "connectToYNAB");
    };
    const { data, isLoading } = (0, useSQLQuery2_1.useSQLQuery2)("user", "getAllUserData", email, !!user?.email);
    let evercentData = null;
    if (data?.data) {
        evercentData = { ...data.data };
    }
    return {
        isLoading,
        ...evercentData,
        updateUserDetails,
        updateMonthsAhead,
        updateCategories,
        changeBudget,
        connectToYNAB,
        saveAutoRuns,
        cancelAutoRuns,
    };
}
exports.default = useEvercent;
