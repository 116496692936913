"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDarkMode = useDarkMode;
const react_1 = require("react");
const DARK_MODE = "useDarkMode";
const USE_DARK_MODE = "1";
const DO_NOT_USE_DARK_MODE = "0";
function useDarkMode() {
    const [useDarkMode, setUseDarkMode] = (0, react_1.useState)(false);
    const [darkMode, setDarkMode] = (0, react_1.useState)(localStorage.getItem(DARK_MODE));
    const toggle = () => {
        const newDarkMode = useDarkMode ? DO_NOT_USE_DARK_MODE : USE_DARK_MODE;
        setDarkMode(newDarkMode);
        const htmlEl = document.getElementsByTagName("html")?.item(0);
        if (htmlEl) {
            htmlEl.className = newDarkMode == USE_DARK_MODE ? "dark" : "";
        }
    };
    (0, react_1.useEffect)(() => {
        if (darkMode)
            localStorage.setItem(DARK_MODE, darkMode);
        setUseDarkMode(darkMode != null && darkMode == USE_DARK_MODE);
    }, [darkMode]);
    return { darkMode: useDarkMode, toggle };
}
