"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
function useSaveModal(modalProps, data, inputs) {
    const runMutation = async () => {
        modalProps.setModalIsSaving(true);
        data.mutate(inputs);
    };
    (0, react_1.useEffect)(() => {
        if (data.data) {
            modalProps.setModalIsSaving(false);
        }
    }, [data.data]);
    (0, react_1.useEffect)(() => {
        modalProps.setOnSaveFn(() => runMutation);
    }, []);
    return runMutation;
}
exports.default = useSaveModal;
