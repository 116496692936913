"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CalendarCell = CalendarCell;
const react_1 = __importStar(require("react"));
const calendar_1 = require("@react-aria/calendar");
const i18n_1 = require("@react-aria/i18n");
const date_1 = require("@internationalized/date");
const focus_1 = require("@react-aria/focus");
const utils_1 = require("@react-aria/utils");
function CalendarCell({ state, date }) {
    let ref = (0, react_1.useRef)(null);
    let { cellProps, buttonProps, isSelected, isOutsideVisibleRange, isDisabled, formattedDate, isInvalid, } = (0, calendar_1.useCalendarCell)({ date }, state, ref);
    let isSelectionStart = state.highlightedRange
        ? (0, date_1.isSameDay)(date, state.highlightedRange.start)
        : isSelected;
    let isSelectionEnd = state.highlightedRange
        ? (0, date_1.isSameDay)(date, state.highlightedRange.end)
        : isSelected;
    let { locale } = (0, i18n_1.useLocale)();
    let dayOfWeek = (0, date_1.getDayOfWeek)(date, locale);
    let isRoundedLeft = isSelected && (isSelectionStart || dayOfWeek === 0 || date.day === 1);
    let isRoundedRight = isSelected &&
        (isSelectionEnd ||
            dayOfWeek === 6 ||
            date.day === date.calendar.getDaysInMonth(date));
    let { focusProps, isFocusVisible } = (0, focus_1.useFocusRing)();
    return (react_1.default.createElement("td", { ...cellProps, className: `py-0.5 relative ${isFocusVisible ? "z-10" : "z-0"}` },
        react_1.default.createElement("div", { ...(0, utils_1.mergeProps)(buttonProps, focusProps), ref: ref, hidden: isOutsideVisibleRange, className: `w-8 h-8 outline-none group ${isRoundedLeft ? "rounded-l-full" : ""} ${isRoundedRight ? "rounded-r-full" : ""} ${isSelected
                ? isInvalid
                    ? "bg-red-300"
                    : "bg-blue-300 dark:bg-purple-500"
                : ""} ${isDisabled ? "disabled" : ""}` },
            react_1.default.createElement("div", { className: `w-full h-full rounded-full flex items-center justify-center ${isDisabled && !isInvalid
                    ? "text-gray-400 dark:text-gray-600 hover:cursor-default"
                    : ""} ${isFocusVisible
                    ? "ring-2 group-focus:z-2 ring-blue-600 ring-offset-2"
                    : ""} ${isSelectionStart || isSelectionEnd
                    ? isInvalid
                        ? "bg-red-600 text-white hover:bg-red-700"
                        : "text-white color-accent"
                    : ""} ${isSelected && !isDisabled && !(isSelectionStart || isSelectionEnd)
                    ? isInvalid
                        ? "hover:bg-red-400"
                        : "hover:bg-blue-400 dark:hover:bg-purple-500"
                    : ""} ${!isSelected && !isDisabled
                    ? "hover:bg-blue-100 dark:hover:bg-purple-300 dark:hover:text-black"
                    : ""} ${isDisabled ? "hover:cursor-default" : "hover:cursor-pointer"}` }, formattedDate))));
}
