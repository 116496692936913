"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSQLMutation2 = useSQLMutation2;
const trpc_1 = require("../utils/trpc");
const log_1 = require("../utils/log");
const auth0_react_1 = require("@auth0/auth0-react");
function useSQLMutation2(routerMut, procMut, routerQuery, procQuery, invalidateQuery, updateFn, errRollbackDelay, errRollbackFn) {
    const { user } = (0, auth0_react_1.useAuth0)();
    const utils = trpc_1.trpc.useUtils();
    const email = {
        userEmail: user?.email,
    };
    const optimistic = () => {
        if (!routerQuery || !procQuery)
            return;
        const { query, queryKey } = {
            query: utils[routerQuery][procQuery],
            queryKey: email,
        };
        return async (res, error) => {
            query.cancel();
            const prevData = query.getData(queryKey);
            if (error || res?.err) {
                (0, log_1.logError)(error ?? res?.err);
                query.setData(queryKey, prevData);
            }
            else if (invalidateQuery) {
                query.invalidate();
            }
            else if (updateFn) {
                query.setData(queryKey, updateFn(prevData, res?.data));
            }
        };
    };
    return trpc_1.trpc[routerMut][procMut].useMutation({
        onSettled: optimistic(),
    });
}
