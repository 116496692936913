"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListBox = ListBox;
const React = __importStar(require("react"));
const react_aria_1 = require("react-aria");
const solid_1 = require("@heroicons/react/24/solid");
function ListBox(props) {
    let ref = React.useRef(null);
    let { listBoxRef = ref, state } = props;
    let { listBoxProps } = (0, react_aria_1.useListBox)(props, state, listBoxRef);
    return (React.createElement("ul", { ...listBoxProps, ref: listBoxRef, className: "w-full max-h-72 overflow-y-scroll no-scrollbar outline-none bg-[#F6F9FA] dark:bg-[#161616]" }, [...state.collection].map((item) => item.type === "section" ? (React.createElement(ListBoxSection, { key: item.key, section: item, state: state })) : (React.createElement(Option, { key: item.key, item: item, state: state })))));
}
function ListBoxSection({ section, state }) {
    let { itemProps, headingProps, groupProps } = (0, react_aria_1.useListBoxSection)({
        heading: section.rendered,
        "aria-label": section["aria-label"],
    });
    return (React.createElement(React.Fragment, null,
        React.createElement("li", { ...itemProps, className: "pt-2" },
            section.rendered && (React.createElement("span", { ...headingProps, className: "text-xs font-bold uppercase text-gray-500 mx-3" }, section.rendered)),
            React.createElement("ul", { ...groupProps }, [...section.childNodes].map((node) => (React.createElement(Option, { key: node.key, item: node, state: state })))))));
}
function Option({ item, state }) {
    let ref = React.useRef(null);
    let { optionProps, isDisabled, isSelected, isFocused } = (0, react_aria_1.useOption)({
        key: item.key,
    }, state, ref);
    let text = "text-color-primary";
    if (isSelected) {
        text = "text-blue-500 dark:text-purple-500";
    }
    else if (isDisabled) {
        text = "text-gray-200";
    }
    return (React.createElement("li", { ...optionProps, ref: ref, className: `m-1 rounded-md py-2 px-2 text-sm outline-none hover:bg-blue-200 dark:hover:bg-purple-200 hover:text-black cursor-pointer flex items-center justify-between ${text} ${isSelected ? "font-bold" : ""}` },
        item.rendered,
        isSelected && (React.createElement(solid_1.CheckIcon, { "aria-hidden": "true", className: "w-5 h-5 text-color-accent stroke-2" }))));
}
