"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
function useHierarchyTable(data, createListFn) {
    const [expandedItems, setExpandedItems] = (0, react_1.useState)([]);
    const [listData, setListData] = (0, react_1.useState)([]);
    (0, react_1.useEffect)(() => {
        if (listData.length == 0) {
            setListData(createListFn(data, expandedItems));
        }
    }, [data]);
    return {
        listData,
        expandedItems,
        setListData,
        setExpandedItems,
    };
}
exports.default = useHierarchyTable;
