"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Select = Select;
const React = __importStar(require("react"));
const react_stately_1 = require("react-stately");
const react_aria_1 = require("react-aria");
const solid_1 = require("@heroicons/react/24/solid");
const ListBox_1 = require("./ListBox");
const Popover_1 = require("./Popover");
function Select(props) {
    let state = (0, react_stately_1.useSelectState)(props);
    let ref = React.useRef(null);
    let { labelProps, triggerProps, valueProps, menuProps } = (0, react_aria_1.useSelect)({}, state, ref);
    let { buttonProps } = (0, react_aria_1.useButton)(triggerProps, ref);
    let { focusProps, isFocusVisible } = (0, react_aria_1.useFocusRing)();
    return (React.createElement("div", { ...labelProps, className: "inline-flex flex-col relative w-28" },
        React.createElement("div", { className: "block text-sm font-medium text-gray-700 text-left cursor-default" }, props.label),
        React.createElement(react_aria_1.HiddenSelect, { state: state, triggerRef: ref, label: props.label, name: props.name }),
        React.createElement("button", { ...(0, react_aria_1.mergeProps)(buttonProps, focusProps), ref: ref, className: `p-1 pl-3 relative inline-flex flex-row items-center justify-between rounded-md overflow-hidden cursor-pointer shadow-sm border-2 dark:border bg-white dark:bg-[#161616] outline-none ${isFocusVisible || (state.isOpen && !props.isDisabled)
                ? "border-color-accent"
                : "border-gray-300"} ${props.isDisabled ? "bg-gray-200 hover:cursor-not-allowed" : ""}` },
            React.createElement("span", { ...valueProps, className: `text-md ${state.selectedItem
                    ? props.isDisabled
                        ? "text-[#919090]"
                        : "text-color-primary"
                    : "text-gray-500"} ${props.isDisabled && "hover:cursor-not-allowed"}` }, state.selectedItem
                ? state.selectedItem.rendered
                : "Select an option"),
            React.createElement(solid_1.ChevronDownIcon, { className: `w-5 h-5 ${isFocusVisible ? "text-color-accent" : "text-gray-500"}` })),
        state.isOpen && !props.isDisabled && (React.createElement(Popover_1.Popover, { state: state, triggerRef: ref, placement: "bottom start", className: "w-28" },
            React.createElement(ListBox_1.ListBox, { ...menuProps, state: state })))));
}
