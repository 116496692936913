"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
function useModal({ loadingText, savedText, msBeforeClose, }) {
    const [isOpen, setIsOpen] = (0, react_1.useState)(false);
    const [changesMade, setChangesMade] = (0, react_1.useState)(false);
    const [modalIsSaving, setModalIsSaving] = (0, react_1.useState)(false);
    const [onSaveFn, setOnSaveFn] = (0, react_1.useState)(undefined);
    const showModal = () => {
        setIsOpen(true);
    };
    const closeModal = () => {
        setIsOpen(false);
    };
    return {
        isOpen,
        showModal,
        closeModal,
        changesMade,
        setChangesMade,
        onSaveFn,
        setOnSaveFn,
        modalIsSaving,
        setModalIsSaving,
        loadingText: loadingText || "Saving...",
        savedText: savedText || "Saved!",
        msBeforeClose: msBeforeClose || 2000,
    };
}
exports.default = useModal;
