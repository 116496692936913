"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = MyDatePicker;
const react_1 = __importStar(require("react"));
const datepicker_1 = require("@react-stately/datepicker");
const datepicker_2 = require("@react-aria/datepicker");
const overlays_1 = require("@react-aria/overlays");
const date_1 = require("@internationalized/date");
const Button_1 = require("./datepicker/Button");
const Calendar_1 = require("./datepicker/Calendar");
const DateField_1 = require("./datepicker/DateField");
const Popover_1 = require("./datepicker/Popover");
const outline_1 = require("@heroicons/react/24/outline");
function MyDatePicker(props) {
    const getISODate = (newDate) => {
        return new Date(newDate.year, newDate.month - 1, newDate.day).toISOString();
    };
    const dpProps = {
        value: (0, date_1.parseDate)(props.value.substring(0, 10)),
        label: "DatePickerLabel",
        isDisabled: props.isDisabled,
        minValue: props.minValue
            ? (0, date_1.parseDate)(props.minValue.substring(0, 10))
            : undefined,
        maxValue: props.maxValue
            ? (0, date_1.parseDate)(props.maxValue.substring(0, 10))
            : undefined,
        onChange: (value) => {
            props.onChange(getISODate(value));
        },
        classNamePosition: props.classNamePosition,
    };
    let state = (0, datepicker_1.useDatePickerState)(dpProps);
    let ref = (0, react_1.useRef)(null);
    let { groupProps, labelProps, fieldProps, buttonProps, dialogProps, calendarProps, } = (0, datepicker_2.useDatePicker)(dpProps, state, ref);
    return (react_1.default.createElement(overlays_1.OverlayProvider, null,
        react_1.default.createElement("div", { className: `inline-flex flex-col text-left` },
            react_1.default.createElement("div", { ...groupProps, ref: ref, className: `flex group ${props?.isDisabled && "hover:cursor-not-allowed"}` },
                react_1.default.createElement("div", { className: "bg-white dark:bg-black border border-gray-400 group-hover:border-gray-400 transition-colors rounded-l-md pr-10 group-focus-within:border-blue-900 group-focus-within:group-hover:border-blue-900 dark:group-focus-within:border-purple-800 dark:group-focus-within:group-hover:border-purple-800 p-1 relative flex items-center" },
                    react_1.default.createElement(DateField_1.DateField, { ...fieldProps, isDisabled: props.isDisabled }),
                    state.validationState === "invalid" && (react_1.default.createElement(outline_1.ExclamationCircleIcon, { className: "w-6 h-6 text-red-500 absolute right-1" }))),
                react_1.default.createElement(Button_1.FieldButton, { ...buttonProps, isPressed: state.isOpen, isDisabled: props.isDisabled },
                    react_1.default.createElement(outline_1.CalendarIcon, { className: "w-5 h-5 text-gray-700 dark:text-gray-400 group-focus-within:text-blue-900 dark:group-focus-within:text-purple-800" }))),
            state.isOpen &&
                (props?.isDisabled == undefined || !props.isDisabled) && (react_1.default.createElement(Popover_1.Popover, { ...dialogProps, isOpen: state.isOpen, onClose: () => state.setOpen(false), classNamePosition: props?.classNamePosition || "" },
                react_1.default.createElement(Calendar_1.Calendar, { ...calendarProps }))))));
}
