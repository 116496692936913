"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CalendarButton = CalendarButton;
exports.FieldButton = FieldButton;
const react_1 = __importStar(require("react"));
const button_1 = require("@react-aria/button");
const focus_1 = require("@react-aria/focus");
const utils_1 = require("@react-aria/utils");
function CalendarButton(props) {
    let ref = (0, react_1.useRef)(null);
    let { buttonProps } = (0, button_1.useButton)(props, ref);
    let { focusProps, isFocusVisible } = (0, focus_1.useFocusRing)();
    return (react_1.default.createElement("button", { ...(0, utils_1.mergeProps)(buttonProps, focusProps), ref: ref, className: `p-2 rounded-full ${props.isDisabled ? "text-gray-400 hover:cursor-not-allowed" : ""} ${!props.isDisabled
            ? "hover:bg-blue-100 dark:hover:bg-purple-300 dark:hover:text-black active:bg-blue-200 dark:active:bg-purple-400"
            : ""} outline-none ${isFocusVisible ? "ring-2 ring-offset-2 ring-purple-600" : ""}` }, props.children));
}
function FieldButton(props) {
    let ref = (0, react_1.useRef)(null);
    let { buttonProps, isPressed } = (0, button_1.useButton)(props, ref);
    return (react_1.default.createElement("button", { ...buttonProps, ref: ref, className: `px-6 -ml-px border border-gray-400 transition-colors rounded-r-md dark:bg-black group-focus-within:border-blue-900 group-focus-within:group-hover:border-blue-900 dark:group-focus-within:border-purple-800 dark:group-focus-within:group-hover:border-purple-800 outline-none ${isPressed || props.isPressed ? "bg-gray-200" : "bg-gray-50"} ${props.isDisabled && "hover:cursor-not-allowed"}` }, props.children));
}
exports.default = { CalendarButton, FieldButton };
