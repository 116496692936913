"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.log = exports.logWarn = exports.logError = void 0;
const getDateString = () => {
    const currDate = new Date();
    return ("[" +
        currDate.getFullYear() +
        "-" +
        (currDate.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        currDate.getDate().toString().padStart(2, "0") +
        " " +
        currDate.getHours().toString().padStart(2, "0") +
        ":" +
        currDate.getMinutes().toString().padStart(2, "0") +
        ":" +
        currDate.getSeconds().toString().padStart(2, "0") +
        "]");
};
exports.logError = console.error.bind(console, getDateString(), "//");
exports.logWarn = console.warn.bind(console, getDateString(), "//");
exports.log = console.log.bind(console, getDateString(), "//");
