"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DateField = DateField;
const react_1 = __importStar(require("react"));
const i18n_1 = require("@react-aria/i18n");
const datepicker_1 = require("@react-stately/datepicker");
const datepicker_2 = require("@react-aria/datepicker");
const date_1 = require("@internationalized/date");
function DateField(props) {
    let { locale } = (0, i18n_1.useLocale)();
    let state = (0, datepicker_1.useDateFieldState)({
        ...props,
        locale,
        createCalendar: date_1.createCalendar,
    });
    let ref = (0, react_1.useRef)(null);
    let { fieldProps } = (0, datepicker_2.useDateField)(props, state, ref);
    return (react_1.default.createElement("div", { ...fieldProps, ref: ref, className: "flex" }, state.segments.map((segment, i) => (react_1.default.createElement(DateSegment, { key: i, idx: i, segment: segment, state: state, isDisabled: props.isDisabled })))));
}
function DateSegment({ idx, segment, state, isDisabled, }) {
    let ref = (0, react_1.useRef)(null);
    let { segmentProps } = (0, datepicker_2.useDateSegment)(segment, state, ref);
    return (react_1.default.createElement("div", { ...segmentProps, ref: ref, style: {
            ...segmentProps.style,
            minWidth: String(segment?.maxValue).length,
        }, className: `px-0.5 box-content tabular-nums text-right outline-none rounded-sm focus:bg-blue-900 dark:focus:bg-purple-800 focus:text-white group ${!segment.isEditable ? "text-gray-500" : "text-gray-800"}` },
        react_1.default.createElement("span", { "aria-hidden": "true", className: "block w-full text-center italic text-gray-500 group-focus:text-white", style: {
                visibility: segment.isPlaceholder ? "visible" : "hidden",
                height: segment.isPlaceholder ? "" : 0,
                pointerEvents: "none",
            } }, segment.placeholder),
        react_1.default.createElement("div", { className: `${!isDisabled && segment.text != "/" && "hover:cursor-pointer"} dark:text-white` }, segment.isPlaceholder
            ? ""
            : idx % 2 == 0
                ? segment.text.padStart(2, "0")
                : segment.text)));
}
